const MainRoutes = {
    path: '/',
    component: () => import('@/components/Layouts/Layout.vue'),
    meta: {},
    children: [
        {
            path: '/',
            component: () => import('@/components/Layouts/Default.vue'),
            children: [
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: () => import('@/components/Dashboards/Dashboard.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Dashboard'
                    }
                },
                {
                    name: 'user',
                    path: '/user',
                    component: () => import('@/components/Users/UserList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Users'
                    }
                },
                {
                    name: 'user.show',
                    path: '/user/:userId',
                    component: () => import('@/components/Users/UserShow.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'View Customer'
                    }
                },
                {
                    name: 'deposit-withdraw',
                    path: '/deposit-withdraw',
                    component: () => import('@/components/CreditTransferApplications/DepositWithdrawTabs.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Deposit / Withdraw'
                    }
                },
                {
                    name: 'position',
                    path: '/position',
                    component: () => import('@/components/Positions/PositionTabs.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Positions'
                    }
                },
                {
                    name: 'downline',
                    path: '/downline',
                    component: () => import('@/components/Downlines/DownlineList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Downlines'
                    }
                },
                {
                    name: 'strategy',
                    path: '/crypto-bot',
                    component: () => import('@/components/Strategies/StrategyList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Crypto Bots'
                    }
                },
                {
                    name: 'profile',
                    path: '/profile',
                    component: () => import('@/components/Profiles/Profile.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Profile'
                    }
                }
            ]
        }
    ]
};

export default MainRoutes;
